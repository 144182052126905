import { useEffect, useState } from 'react'
import LeftArrow from './assets/images/leftArrow'
import RightArrow from './assets/images/rightArrow'
import SearchIcon from './assets/images/searchIcon'
import ReactPaginate from 'react-paginate'
import moment from 'moment'
import PuffLoader from 'react-spinners/PuffLoader'
import BeatLoader from 'react-spinners/BeatLoader'
import Logo from './assets/images/logo.png'
import FloatingLabel from 'floating-label-react'
import Placeholder from './assets/images/placeholder.jpeg'
import Playlist from './assets/images/playlist.png'
import { DrPublish } from './assets/js/DrPublish'
import Upload from './assets/images/upload.png'

import 'jquery'

function App() {
  const [loading, setLoading] = useState(true)
  const [loadingExtra, setLoadingExtra] = useState(false)
  const [partners, setPartners] = useState([])
  const [activePartner, setActivePartner] = useState(0)
  const [channels, setChannels] = useState([])
  const [activeChannel, setActiveChannel] = useState(0)
  const [videos, setVideos] = useState([])
  const [videoType, setVideoType] = useState('video')
  const [initialPage, setInitialPage] = useState(0)
  const [pagination, setPagination] = useState({})
  const [searchText, setSearchText] = useState('')
  const [showUpload, setShowUpload] = useState(false)
  const [videoLoading, setVideoLoading] = useState(false)
  const [uploadType, setUploadType] = useState(0)
  const [uploadName, setUploadName] = useState('')
  const [uploadDescription, setUploadDescription] = useState('')
  const [uploadURL, setUploadURL] = useState('')
  const [uploadDate, setUploadDate] = useState('')
  const [uploadTags, setUploadTags] = useState('')
  const [uploadImage, setUploadImage] = useState('')
  const [videoUploading, setVideoUploading] = useState(false)
  const [players, setPlayers] = useState([])
  const [activePlayer, setActivePlayer] = useState(0)
  const [playersLoading, setPlayersLoading] = useState(false)

  useEffect(() => {
    getPartners()
  }, [])

  const endpoint = 'https://5y9as9d6vl.execute-api.eu-west-1.amazonaws.com/prod'

  const getPlayers = async (partnerId) => {
    setPlayersLoading(true)
    await fetch(
      `${endpoint}/players?partnerId=${partnerId ? partnerId : activePartner}`
    )
      .then(async (response) => {
        const result = await response.json()

        const players = result.data

        const playerList = []

        players.map((player) => {
          const id = player.Player.id
          const name = player.Player.name

          playerList.push({
            id,
            name,
          })
        })

        const defaultPlayer = players.find(
          (player) => player.Player.default === 1
        )

        setPlayers(playerList)
        setActivePlayer(
          defaultPlayer ? defaultPlayer.Player.id : playerList[0].id
        )
        setPlayersLoading(false)
      })
      .catch((err) => console.error(err))
  }

  const getPartners = async () => {
    await fetch(`${endpoint}/partners`)
      .then(async (response) => {
        let result = await response.json()

        const eksterne = result.find(
          (e) => e.Partner.domain_short === 'JFM: Eksterne'
        )

        if (eksterne) {
          const eksterneId = result.findIndex(
            (e) => e.Partner.domain_short === 'JFM: Eksterne'
          )

          const tempArray = result.filter((e, index) => index !== eksterneId)

          tempArray.unshift(eksterne)

          result = tempArray
        }

        setPartners(result)

        setActivePartner(result[0].Partner.id)

        await getPlayers(result[0].Partner.id)

        await getVideos(0, result[0].Partner.id)
      })
      .catch((err) => console.error(err))
  }

  const changePartner = (partnerId) => {
    setActivePartner(partnerId)

    getPlayers(partnerId)

    if (videoType === 'video') {
      getVideos(0, partnerId)
    }

    if (videoType === 'livestream') {
      getLivestreams(0, partnerId)
    }

    if (videoType === 'playlist') {
      getPlaylists(0, partnerId)
    }
  }

  const getVideos = async (page, partnerId) => {
    if (videoType !== 'video') {
      setLoadingExtra(true)
      setVideos([])
      setVideoType('video')
    }

    if (page) {
      setInitialPage(page - 1)
    } else {
      setInitialPage(0)
    }

    await fetch(
      `${endpoint}/videos?partnerId=${
        partnerId ? partnerId : activePartner
      }&page=${page ? page : 1}&searchText=${searchText}`
    )
      .then(async (response) => {
        const result = await response.json()
        setVideos(result.data)
        setPagination(result.paging)
        setLoading(false)
        setLoadingExtra(false)
      })
      .catch((err) => console.error(err))
  }

  const getLivestreams = async (page, partnerId) => {
    if (videoType !== 'livestream') {
      setLoadingExtra(true)
      setVideos([])
      setVideoType('livestream')
    }

    if (page) {
      setInitialPage(page - 1)
    } else {
      setInitialPage(0)
    }

    await fetch(
      `${endpoint}/livestreams?partnerId=${
        partnerId ? partnerId : activePartner
      }&page=${page ? page : 1}&searchText=${searchText}`
    )
      .then(async (response) => {
        const result = await response.json()

        setVideos(result.data)
        setPagination(result.paging)
        setLoading(false)
        setLoadingExtra(false)
      })
      .catch((err) => console.error(err))
  }

  const getPlaylists = async (page, partnerId) => {
    if (videoType !== 'playlist') {
      setLoadingExtra(true)
      setVideos([])
      setVideoType('playlist')
    }

    if (page) {
      setInitialPage(page - 1)
    } else {
      setInitialPage(0)
    }

    await fetch(
      `${endpoint}/playlists?partnerId=${
        partnerId ? partnerId : activePartner
      }&page=${page ? page : 1}&searchText=${searchText}`
    )
      .then(async (response) => {
        const result = await response.json()
        setVideos(result.data)
        setPagination(result.paging)
        setLoading(false)
        setLoadingExtra(false)
      })
      .catch((err) => console.error(err))
  }

  const search = () => {
    if (videoType === 'video') {
      getVideos()
    }

    if (videoType === 'playlist') {
      getPlaylists()
    }

    if (videoType === 'livestream') {
      getLivestreams()
    }
  }

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      search()
    }
  }

  const insertScript = (video) => {
    //Lav support for livestream

    let name
    let image
    let id
    let tag

    if (videoType === 'video') {
      image = video.image
      name = video.name
      id = video.id
      tag = 'video'
    }

    if (videoType === 'playlist') {
      image = Playlist
      name = video.name
      id = video.id
      tag = 'playlist'
    }

    if (videoType === 'livestream') {
      image = Placeholder
      name = video.name
      id = video.video_id
      tag = 'video'
    }

    let markup =
      '<div class="container" style="position: relative; padding-bottom: 56.25%; height: 0; overflow: hidden; max-width:100%;">'
    markup += `<img style="position: absolute; top: 0; left: 0;" src="${image}" />`
    markup += `<div style="position: absolute; top: 0; left: 0; width: 100%; padding: 6px; background: rgba(255,255,255,0.5);  font-weight: bold;">`
    markup += `<div>${
      videoType.charAt(0).toUpperCase() + videoType.slice(1) + ': ' + name
    }</div>`
    markup += `</div>`
    markup += `</div>`

    const embedCode = `<div id="vs_${activePartner}${activePlayer}${id}" style="width: 16; height: 9"></div>
  <script type="text/javascript">
    var _bp = _bp || []
    _bp.push({
      div: "vs_${activePartner}${activePlayer}${id}",
      obj: { id: "${activePlayer}", width: "16", height: "9", "${tag}": "${id}" },
    })
  </script>
  <script type="text/javascript" async src="https://player.videosyndicate.io/player/build/videosyndicate.min.js"></script>`

    DrPublish.insert(markup, embedCode, name)
  }

  const getName = (name) => {
    const firstPart = name.replace('JFM: ', '')
    const final = firstPart.charAt(0).toUpperCase() + firstPart.slice(1)

    return final
  }

  const getChannels = async () => {
    setChannels([])

    await fetch(`${endpoint}/channels?partnerId=${activePartner}`)
      .then(async (response) => {
        const result = await response.json()
        setChannels(result.data)
        setActiveChannel(result.data[0].Channel.id)
        setLoading(false)
      })
      .catch((err) => console.error(err))
  }

  const changeUploadType = (type) => {
    setUploadType(type)

    if (type === 1) {
      getChannels()
      setUploadName('')
      setUploadDescription('')
      setUploadURL('')
      setUploadDate('')
      setUploadTags('')
      setUploadImage('')
    } else if (type === 2) {
      setVideoLoading(true)
      setTimeout(() => {
        $BridApi.Uploader.init({
          access_token: '6541592560e7c94f6d026f56e67f24ab46ad7a3d',
          partnerId: activePartner,
        })
        setVideoLoading(false)
      }, 500)
    }
  }

  const uploadVideo = async () => {
    if (!uploadURL || !uploadName || !activeChannel) {
      alert('Please fill in the required fields')
      return
    }

    setVideoUploading(true)

    await fetch(`${endpoint}/video`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        mp4: uploadURL,
        image: uploadImage,
        name: uploadName,
        description: uploadDescription,
        tags: uploadTags,
        publish: uploadDate,
        channel_id: activeChannel,
        partner_id: activePartner,
      }),
    })
      .then((response) => {
        if (response.status === 200) {
          setVideoUploading(false)
          doneUploading()
        } else {
          setVideoUploading(false)
          alert('An error occured, please try again or contact support.')
        }
      })
      .catch((err) => {
        setVideoUploading(false)
        alert('An error occured, please try again or contact support.')
        console.error(err)
      })
  }

  const doneUploading = () => {
    setShowUpload(false)
    setUploadType(0)

    setLoading(true)

    if (videoType === 'video') {
      getVideos()
    }

    if (videoType === 'livestream') {
      getLivestreams()
    }

    if (videoType === 'playlist') {
      getPlaylists()
    }
  }

  const videoPreviewLink = (video) => {
    const links = Object.entries(video)
    const link = links[links.length - 1][1]

    const finalLink =
      link
        .replace('//', '')
        .replace('https://', '')
        .replace('https:', '')
        .replace(/^/, 'https://') || ''

    return finalLink
  }

  const showContent = () => {
    if (videoType === 'video') {
      return videos.map((video, index) => (
        <div
          style={{
            marginBottom: videos.length - 1 === index ? 0 : 20,
          }}
          key={index}
          className='video'
        >
          <div className='videoThumb'>
            <img
              style={{ width: '100%', height: '100%' }}
              alt={video.Video.name}
              src={video.Video.image}
            />
          </div>
          <div className='videoContent'>
            <p className='videoName'>{video.Video.name}</p>
            <div className='videoBottom'>
              <p className='videoDate'>
                {moment(new Date(video.Video.publish)).format('DD-MM-YYYY')}
              </p>
              <div className='linkBox'>
                <a
                  onClick={() =>
                    !video.Video.source &&
                    alert(
                      'No preview available, if you just uploaded a video please refresh and try again'
                    )
                  }
                  className='videoPreview'
                  href={
                    video.Video.source
                      ? videoPreviewLink(video.Video.source)
                      : undefined
                  }
                  target={video.Video.source ? '_blank' : undefined}
                >
                  Preview
                </a>
                <p
                  onClick={() => insertScript(video.Video)}
                  className='videoLink'
                >
                  Insert into article
                </p>
              </div>
            </div>
          </div>
        </div>
      ))
    }

    if (videoType === 'livestream') {
      return videos.map((video, index) => (
        <div
          style={{
            marginBottom: videos.length - 1 === index ? 0 : 20,
          }}
          key={index}
          className='video'
        >
          <div className='videoThumb'>
            <img
              style={{ width: '100%', height: '100%' }}
              alt={video.Livestream.name}
              src={Placeholder}
            />
          </div>
          <div className='videoContent'>
            <p className='videoName'>{video.Livestream.name}</p>
            <div className='videoBottom'>
              <p className='videoDate'>
                {moment(new Date(video.Livestream.created)).format(
                  'DD-MM-YYYY'
                )}
              </p>
              <p
                onClick={() => insertScript(video.Livestream)}
                className='videoLink'
              >
                Insert into article
              </p>
            </div>
          </div>
        </div>
      ))
    }

    if (videoType === 'playlist') {
      return videos.map((video, index) => (
        <div
          style={{
            marginBottom: videos.length - 1 === index ? 0 : 20,
          }}
          key={index}
          className='video'
        >
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
            className='videoThumb'
          >
            <img
              style={{ width: '60%', height: '60%' }}
              alt={video.Playlist.name}
              src={Playlist}
            />
          </div>
          <div className='videoContent'>
            <p className='videoName'>{video.Playlist.name}</p>
            <div className='videoBottom'>
              <p className='videoDate'>
                {moment(new Date(video.Playlist.created)).format('DD-MM-YYYY')}
              </p>
              <p
                onClick={() => insertScript(video.Playlist)}
                className='videoLink'
              >
                Insert into article
              </p>
            </div>
          </div>
        </div>
      ))
    }
  }

  return (
    <div className='container'>
      <img
        style={{ height: 50, marginTop: 48, marginBottom: 48 }}
        src={Logo}
        alt='VideoSyndicate logo'
      />

      {!loading ? (
        <>
          {showUpload ? (
            <div style={{ width: '100%' }}>
              {uploadType === 1 ? (
                <>
                  <FloatingLabel
                    placeholder='Video URL*'
                    type='text'
                    style={{ marginBottom: 16 }}
                    value={uploadURL}
                    onChange={(e) => setUploadURL(e.currentTarget.value)}
                  />
                  <FloatingLabel
                    placeholder='Image URL (leave empty for auto-generate)'
                    type='text'
                    style={{ marginBottom: 16 }}
                    value={uploadImage}
                    onChange={(e) => setUploadImage(e.currentTarget.value)}
                  />
                  <FloatingLabel
                    placeholder='Title*'
                    type='text'
                    style={{ marginBottom: 16 }}
                    value={uploadName}
                    onChange={(e) => setUploadName(e.currentTarget.value)}
                  />
                  <FloatingLabel
                    placeholder='Description'
                    type='text'
                    style={{ marginBottom: 16 }}
                    value={uploadDescription}
                    onChange={(e) =>
                      setUploadDescription(e.currentTarget.value)
                    }
                  />
                  <FloatingLabel
                    placeholder='Tags (comma separated)'
                    type='text'
                    style={{ marginBottom: 16 }}
                    value={uploadTags}
                    onChange={(e) => setUploadTags(e.currentTarget.value)}
                  />
                  <FloatingLabel
                    placeholder='Date (leave empty for today)'
                    type='text'
                    style={{ marginBottom: 16 }}
                    value={uploadDate}
                    onChange={(e) => setUploadDate(e.currentTarget.value)}
                  />
                  <p style={{ fontFamily: 'Roboto-Medium, Arial' }}>Channel*</p>
                  {channels.length > 0 ? (
                    <select
                      defaultValue={activeChannel}
                      className='selector'
                      onChange={(e) => setActiveChannel(e.target.value)}
                    >
                      {channels.map((channel, index) => (
                        <option value={channel.Channel.id} key={index}>
                          {channel.Channel.name}
                        </option>
                      ))}
                    </select>
                  ) : (
                    <div className='selector'>
                      <BeatLoader
                        color={'#1D59C7'}
                        loading={true}
                        size={8}
                        aria-label='Loading Spinner'
                        data-testid='loader'
                      />
                    </div>
                  )}
                  <div className='buttons'>
                    <div
                      className='button'
                      onClick={() => (setShowUpload(false), setUploadType(0))}
                    >
                      <p>Go back</p>
                    </div>

                    <div className='uploadButton' onClick={() => uploadVideo()}>
                      {videoUploading ? (
                        <BeatLoader
                          color={'#FFFFFF'}
                          loading={videoUploading}
                          size={8}
                          aria-label='Loading Spinner'
                          data-testid='loader'
                        />
                      ) : (
                        <p>Upload video</p>
                      )}
                    </div>
                  </div>
                </>
              ) : uploadType === 2 ? (
                <>
                  <div className='uploadContainer'>
                    <div className='uploaderOverwrite' />
                    <div id='bridApiUploader'></div>
                  </div>

                  {videoLoading && (
                    <div className='uploadPlaceholder'>
                      <PuffLoader
                        color={'#1D59C7'}
                        loading={videoLoading}
                        size={102}
                        aria-label='Loading Spinner'
                        data-testid='loader'
                      />
                    </div>
                  )}
                  <div style={{ marginTop: 16 }} className='buttons'>
                    <div className='button' onClick={() => doneUploading()}>
                      <p>Go back</p>
                    </div>
                  </div>
                </>
              ) : (
                <div>
                  <div style={{ marginBottom: 0 }} className='buttons'>
                    <div
                      className='uploadButton'
                      style={{ height: 60 }}
                      onClick={() => changeUploadType(1)}
                    >
                      <p style={{ fontSize: 18 }}>URL link</p>
                    </div>

                    <div
                      style={{ height: 60 }}
                      className='uploadButton'
                      onClick={() => changeUploadType(2)}
                    >
                      <p style={{ fontSize: 18 }}>Select file</p>
                    </div>
                  </div>

                  <div className='buttons'>
                    <div
                      className='button'
                      onClick={() => setShowUpload(false)}
                    >
                      <p>Go back</p>
                    </div>
                  </div>
                </div>
              )}
            </div>
          ) : (
            <>
              <div style={{ width: '100%' }}>
                <p style={{ fontFamily: 'Roboto-Medium, Arial' }}>
                  Publications
                </p>
                <select
                  defaultValue={activePartner}
                  className='selector'
                  onChange={(e) => changePartner(e.target.value)}
                >
                  {partners.map((partner, index) => (
                    <option value={partner.Partner.id} key={index}>
                      {getName(partner.Partner.domain_short)}
                    </option>
                  ))}
                </select>

                <p style={{ fontFamily: 'Roboto-Medium, Arial' }}>Players</p>
                {!playersLoading ? (
                  <select
                    key={players[0].id}
                    defaultValue={activePlayer}
                    className='selector'
                    onChange={(e) => setActivePlayer(e.target.value)}
                  >
                    {players.map((player, index) => (
                      <option value={player.id} key={index}>
                        {player.name}
                      </option>
                    ))}
                  </select>
                ) : (
                  <div className='selector'>
                    <BeatLoader
                      color={'#1D59C7'}
                      loading={playersLoading}
                      size={8}
                      aria-label='Loading Spinner'
                      data-testid='loader'
                    />
                  </div>
                )}

                <div className='buttons'>
                  <div
                    className={
                      videoType === 'video' ? 'buttonActive' : 'button'
                    }
                    onClick={() => getVideos()}
                  >
                    <p>Videos</p>
                  </div>

                  <div
                    className={
                      videoType === 'livestream' ? 'buttonActive' : 'button'
                    }
                    onClick={() => getLivestreams()}
                  >
                    <p>Livestreams</p>
                  </div>

                  <div
                    className={
                      videoType === 'playlist' ? 'buttonActive' : 'button'
                    }
                    onClick={() => getPlaylists()}
                  >
                    <p>Playlists</p>
                  </div>
                </div>

                <div
                  onClick={() => setShowUpload(true)}
                  className='uploadButton'
                >
                  <img
                    style={{ height: 16 }}
                    src={Upload}
                    alt='upload video icon'
                  />
                  <p style={{ marginLeft: 8 }}>Upload video</p>
                </div>
              </div>

              <div className='searchField'>
                <FloatingLabel
                  placeholder='Search...'
                  type='text'
                  value={searchText}
                  onChange={(e) => setSearchText(e.currentTarget.value)}
                  onKeyDown={(event) => handleKeyPress(event)}
                />
                <div onClick={() => search()} className='searchIcon'>
                  <SearchIcon />
                </div>
              </div>

              {videos.length > 0 ? (
                <>
                  <div className='videoContainer'>{showContent()}</div>
                  <ReactPaginate
                    breakLabel='...'
                    nextLabel={<RightArrow />}
                    className='pagination'
                    onPageChange={(e) =>
                      videoType === 'video'
                        ? getVideos(e.selected + 1)
                        : videoType === 'livestream'
                        ? getLivestreams(e.selected + 1)
                        : videoType === 'playlist' &&
                          getPlaylists(e.selected + 1)
                    }
                    forcePage={initialPage}
                    marginPagesDisplayed={1}
                    pageRangeDisplayed={3}
                    pageCount={
                      videoType === 'video'
                        ? Math.ceil(pagination.Video.count / 10)
                        : videoType === 'livestream'
                        ? Math.ceil(pagination.Livestream.count / 10)
                        : videoType === 'playlist' &&
                          Math.ceil(pagination.Playlist.count / 10)
                    }
                    previousLabel={<LeftArrow />}
                  />
                </>
              ) : (
                <div>
                  {loadingExtra ? (
                    <BeatLoader
                      color={'#1D59C7'}
                      loading={loadingExtra}
                      size={12}
                      aria-label='Loading Spinner'
                      data-testid='loader'
                    />
                  ) : (
                    <p>No results found</p>
                  )}
                </div>
              )}
            </>
          )}
        </>
      ) : (
        <PuffLoader
          color={'#1D59C7'}
          loading={loading}
          size={102}
          aria-label='Loading Spinner'
          data-testid='loader'
        />
      )}
    </div>
  )
}

export default App
